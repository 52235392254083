import { BaseDto } from '@/shared/dtos/base-dto';
import { mensaje_ticket } from './mensaje_ticket';

export class ticket extends BaseDto {
    public nombre !: string;
    public descripcion !: string;
    public fecha_inicio!:Date;
    public fecha_fin!:Date;
    public id_responsable !: number;
    public id_prioridad !: number;
    public id_categoria !: number;
    public resolucion !: string;
    public id_estado !: number;
    public mensajes!:mensaje_ticket[];
 } 
